body {
  font: unset;
  font-family: 'system-ui';
  font-size: 15px;
  background-color: transparent;
}

.accordion-body {
  padding: 0 !important;
}

.navbar-customclass {
  background-color: #3e3f83;
}

.navbar {
  padding: 0.2rem 0.5rem !important;
  font-weight: 500;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8) !important;
}
.navbar-brand a {
  width: 400px !important;
}

.navbar a {
  margin-right: 15px !important;
  font-size: 18px;
  text-decoration: none !important;
}

input.k-input,
textarea.k-textarea {
  padding: 2px 4px !important;
}

label.k-label {
  margin-top: 1px !important;
  margin-bottom: 0px;
  vertical-align: middle !important;
}

.k-drawer {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: whitesmoke;
}

.k-drawer-item {
  padding: 8px 8px;
  color: inherit;
  font-size: 15px;
}

.k-drawer-item.k-selected {
  color: #424242;
  background-color: #d0d0d0;
}

.k-drawer-item.k-selected:hover {
  color: #424242;
  background-color: #e0e0e0;
}

.active-link {
  color: white !important;
  border-bottom-color: white;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
}

.inactive-link {
  color: #e0e0e0 !important;
  border-bottom-color: white;
  border-bottom-width: 0px;
  border-bottom-style: solid;
}

.k-drawer-container {
  position: fixed;
  width: 100%;
  height: 100%;
}

.k-drawer-item .k-icon {
  margin: 0px;
  margin-left: 6px;
  margin-right: 2px;
}

.k-icon {
  font-size: 20px;
}

.k-grid {
  font-size: 14px;
  /*background-color: transparent !important;*/
}

.k-grid .no-padding {
  padding-right: 2px !important;
}

.k-tabstrip > .k-content {
  padding: 0px;
  padding-top: 15px;
}

/*
.k-grid-header {
  background-color: white !important;
}
*/
.rr-equal-columns table {
  table-layout: fixed;
}

.k-drawer-content {
  overflow: scroll !important;
  height: 100%;
  padding-bottom: 20px;
}

.k-detail-cell {
  padding: 0em !important;
  /*padding-top: 6px !important; Messes stage failure modality test breakdown*/
  padding-bottom: 6px !important;
  padding-right: 12px !important;
  color: peru;
}

.k-detail-cell td {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  color: peru;
}

.k-grid tr.k-alt {
  background-color: rgba(0, 0, 0, 0.05);
}

.k-detail-cell tr.k-alt {
  background-color: transparent !important;
}

.k-detail-cell .k-column-title {
  font-weight: 400 !important;
}
.k-grid-header th.k-header,
.k-filter-row th {
  line-height: inherit;
  padding: 0.5em 0.8em 0.3em 0.2em !important;
  /*background-color: transparent !important;*/
  border-width: 0 0 1px 0px !important;
  font-weight: 500;
  font-size: 14px;
}

/*
.k-grid-header,
.k-grid-footer,
.k-pager-wrap,
.k-group-footer,
.k-grouping-header {
  background-color: transparent !important;
}
*/
.k-grid-footer td {
  background-color: white !important;
}

.k-grid {
  border-style: none !important;
}

.k-grid-content {
  background-color: transparent !important;
}

.k-grid td {
  border-left-style: none !important;
  border-right-style: none !important;
  border-width: 0 0 0 1px;
  /*padding: 0.3em 1em 0.25em 0.2em;*/
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  color: #505050;
  line-height: 22px !important;
  white-space: nowrap;
}

.k-grid-header-wrap,
.k-grid-footer-wrap {
  border-style: none !important;
}

.k-grid .k-hierarchy-cell > .k-icon,
.k-grid .k-drag-cell > .k-icon {
  padding: 0;
}

/* Fix grid width in tabstrip */
.k-animation-container {
  width: 100%;
}

.k-tabstrip-items-wrapper .k-item {
  color: #997009;
}

/*
.k-cell-inner > .k-link {
  padding-top: 0px;
}
*/

.rr-center-column {
  display: block;
  text-align: center !important;
}

.pc-header-back-icon {
  color: #3e3f83;
  font-size: 30px;
  vertical-align: super;
  margin-right: 8px;
}

.pc-header-back-icon :hover {
  color: #9FA2FC;
}

.pc-header-icon {
  color: #464775;
  float: right;
  height: 100%;
  text-decoration: none;
  font-size: 30px;
  margin-left: 12px;
  padding-bottom: 7px;
}

.pc-breadcrumb {
  font-weight: 400;
  font-size: 18px;
  height: 50px;
  line-height: 1.3em;
  margin-top: 12px; /* margin-top: 10px;  */
  margin-bottom: 12px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: transparent;
}

.pc-breadcrumb:after,
.pc-header-icon:after,
.centered:after {
  height: 100%;
  content: '';
  font-size: 0;
  vertical-align: middle;
  display: inline-block;
}
